import {
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableProps,
} from '@material-ui/core'
import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'

type CustomTableData = { [x: string]: unknown }

export type CustomTableColumnBase = {
  header?: React.ReactNode
  className?: string
  headerClassName?: string
  cellClassName?: string
}

/**
 * Define type of custom table columns.
 * Columns can either use the default formatting function, in which case the
 * `key` field must be a key of the provided table data. Alternatively, if the
 * `key` field is an arbitrary string, a `format` function must be provided.
 */
export type CustomTableColumn<T extends CustomTableData> =
  | (CustomTableColumnBase & {
      key: keyof T
      format?: undefined
    })
  | (CustomTableColumnBase & {
      key: string
      format: (Row: T) => React.ReactNode | React.ReactNode
    })

type CustomTableProps<T extends CustomTableData> = {
  /** Array of data to populate the table with */
  tableData: T[]
  /** List of column definitions */
  columns: CustomTableColumn<T>[]
  /**
   * If the rows don't have an ID property that uniquely identifies them, you
   * can specify a unique key, or a function that returns a unique string based
   * on the row data
   */
  keyRowsBy: (keyof T & string) | ((RowEntry: T) => string | number)
  /** If false will hide the table headers */
  showHeaders?: boolean
  /** Classes to apply to various parts of the table */
  classes?: TableProps['classes'] & {
    header?: string
    body?: string
    clickableRow?: string
  }
  /**
   * If true will remove left/right padding from table cells/headers at the
   * beginning and end of each row
   */
  horizontalGutters?: boolean
  /** Function to call on row click */
  onRowClick?: (Row: T) => void
} & TableProps

function defaultKeyRowsBy(TableEntry: CustomTableData): string | number {
  if (typeof TableEntry.id !== 'string' || typeof TableEntry.id !== 'number')
    throw new Error(
      'Default row key uses the id property, which must be a string or number. Either add an id property to your table data, or supply a keyRowsBy property',
    )
  return TableEntry.id
}

function keyRowsByString<T extends CustomTableData>(stringKey: keyof T) {
  return (data: T): string | number => {
    const key = data[stringKey]
    if (typeof key !== 'string' && typeof key !== 'number')
      throw new Error('Key property must be a string or number')
    return key
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&.remove-horizontal-gutters': {
      '& > tbody > tr > td:first-of-type, & > thead > tr > th:first-of-type': {
        paddingLeft: 0,
      },
      '& > tbody > tr > td:last-of-type, & > thead > tr > th:last-of-type': {
        paddingRight: 0,
      },
    },
  },
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}))

/** Component for rendering an array of data into a formatted table */
function CustomTable<T extends CustomTableData>(
  props: PropsWithChildren<CustomTableProps<T>>,
): JSX.Element | null {
  const classesFromUseStyles = useStyles()
  const {
    tableData,
    columns,
    keyRowsBy,
    showHeaders = true,
    classes,
    className,
    horizontalGutters = false,
    onRowClick,
    ...rest
  } = props
  if (!tableData || tableData.length === 0) return null
  const keyRowsByFn =
    typeof keyRowsBy === 'string'
      ? keyRowsByString(keyRowsBy)
      : typeof keyRowsBy === 'function'
      ? keyRowsBy
      : defaultKeyRowsBy
  return (
    <Table
      className={classnames(classesFromUseStyles.root, className, {
        'remove-horizontal-gutters': !horizontalGutters,
      })}
      {...rest}
    >
      {showHeaders && (
        <TableHead>
          <TableRow>
            {columns.map(({ key, header, className, headerClassName }, i) => (
              <TableCell
                key={`${key}-${i}`}
                className={classnames(
                  classes?.header,
                  `header-${key}`,
                  className,
                  headerClassName,
                )}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody className={classes?.body}>
        {tableData.map((rowData) => (
          <TableRow
            key={keyRowsByFn(rowData)}
            onClick={onRowClick ? () => onRowClick(rowData) : undefined}
            className={classnames({
              [classesFromUseStyles.clickableRow]: !!onRowClick,
            })}
          >
            {columns.map(({ key, format, className, cellClassName }, i) => {
              const cellValue =
                typeof format === 'function'
                  ? format(rowData)
                  : (rowData[key] as React.ReactNode)
              return (
                <TableCell
                  key={`${key}-${i}`}
                  className={classnames(
                    `cell-${key}`,
                    className,
                    cellClassName,
                  )}
                >
                  {cellValue}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default CustomTable
